<template>
  <div class="container">
    <div class="pdf">
      <div id="pdf"></div>
    </div>
  </div>
</template>

<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'

export default {
  name: 'App',
  data() {
    return {
      pdfh5: null,
    }
  },
  mounted() {
    //实例化
    this.pdfh5 = new Pdfh5('#pdf', {
      pdfurl: this.$route.query.fileUrl,
    })
    //监听完成事件
    this.pdfh5.on('complete', function (status, msg, time) {
      console.log('状态：' + status + '，信息：' + msg + '，耗时：' + time + '毫秒，总页数：' + this.totalNum)
    })
  },
}
</script>

<style lang="scss">
.pdf {
  min-height: 1000px;
}

.pdfjs {
  min-height: 1000px;
  .loadingBar {
    display: none !important;
  }
}
</style>
